export const lang = {
	// Titel jeder Seite
	title: 'Produktdetails',
	noticeTag: 'Hinweis',
	noticeValue: 'Der auf der Seite angezeigte Preis ist ein Richtwert. Bitte kontaktieren Sie den Verkäufer für ein detailliertes Angebot',
	shop: 'Geschäft',
	shopSeller: 'Verkäufer des Geschäfts',
	shopSellerPhone: 'Telefon des Verkäufers',
	count: 'Menge',
	stock: 'Lagerbestand',
	buyNow: 'Jetzt kaufen',
	addToCart: 'In den Warenkorb legen',
	outOfStock: 'Nicht vorrätig',
	goodsDetail: 'Produktdetails',
	goodsProperty: 'Produkteigenschaften',
	goodsComment: 'Produktbewertungen',
	hotSales: '24-Stunden-Bestseller',
	recommend: 'Empfehlungen für ähnliche Produkte',
	sales: 'Verkaufszahlen und Beliebtheit',
	views: 'Produktaufrufe',
	collect: 'Beliebtheit der Favoriten',
	brand: 'Markeninformationen',
	findHots: 'Top-Verkäufe anzeigen',
	rank: 'Aufruf-Rang',
	collected: 'Produkt zu Favoriten hinzugefügt',
	brandIndex: 'Markenstartseite',
}