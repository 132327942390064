export const lang = {
	// Titre de chaque page
	title: 'Détails du produit',
	noticeTag: 'Avertissement',
	noticeValue: 'Le prix affiché sur la page est indicatif, veuillez contacter le vendeur pour un devis détaillé',
	shop: 'Boutique',
	shopSeller: 'Vendeur de la boutique',
	shopSellerPhone: 'Téléphone du vendeur',
	count: 'Quantité',
	stock: 'Stock',
	buyNow: 'Acheter maintenant',
	addToCart: 'Ajouter au panier',
	outOfStock: 'Rupture de stock',
	goodsDetail: 'Détails du produit',
	goodsProperty: 'Propriétés du produit',
	goodsComment: 'Commentaires sur le produit',
	hotSales: 'Top des ventes en 24 heures',
	recommend: 'Recommandations de produits similaires',
	sales: 'Popularité des ventes',
	views: 'Vues du produit',
	collect: 'Popularité des favoris',
	brand: 'Informations sur la marque',
	findHots: 'Voir les meilleures ventes',
	rank: 'Classement des vues',
	collected: 'Produit ajouté aux favoris',
	brandIndex: 'Page d\'accueil de la marque',
}