export const lang = {
	// Umum
	common: {
		// Nama paket bahasa
		name: 'Bahasa Indonesia',
		contact_us: 'Hubungi Kami',
		company_name: 'Nanjing Suoou Pompa dan Valve Co., Ltd.',
		email: 'Email',
		tel: "Telepon",
		submit: 'Kirim',
		home: 'Beranda',
		header_searchInputPlaceholder: 'Masukkan kata kunci produk',
		header_pleaseLogin: 'Halo, silakan login',
		header_freeRegistry: 'Daftar Gratis',
		header_applyEnter: 'Ajukan Bergabung',
		header_myOrder: 'Pesanan Saya',
		header_memberCenter: 'Pusat Member',
		header_helperCenter: 'Pusat Bantuan',
		header_onlineService: 'Layanan Online',
		header_phoneVersion: 'Versi Mobile',
		header_cart: 'Keranjang Belanja Saya',
		footer_customerService: 'Layanan Pelanggan',
		footer_onlineService: 'Layanan Online',
		footer_feedback: 'Umpan Balik Masalah',
		footer_followUs: 'Ikuti Kami',
		footer_wechatPublic: 'Akun Resmi WeChat',
		footer_webo: 'Weibo',
		footer_miniProgram: 'TikTok',
		footer_contract: 'Pembuatan Kontrak Online',
		footer_logistics: 'Logistik Terpercaya',
		footer_quality: 'Jaminan Kualitas Suoou Mall',
		footer_aboutUs: 'Tentang Kami',
		footer_helpCenter: 'Pusat Bantuan',
		footer_afterSell: 'Layanan Purna Jual',
		footer_delivery: 'Pengiriman dan Penerimaan',
		footer_cooperation: 'Kerjasama Bisnis',
		footer_purchase: 'Pembelian Perusahaan',
		footer_openPlatform: 'Platform Terbuka',
		footer_searchRecommend: 'Rekomendasi Pencarian',
		footer_friendshipLink: 'Tautan Persahabatan',
		footer_contactUs: 'Hubungi Kami 24 Jam',
		footer_address: 'Alamat: Kamar 1603-1605, Gedung G1, Greenland Plaza, Jalan Jingyuan No. 8, Nanjing, China',
		hotSales: 'Daftar Terlaris 24 Jam',
		productsList: "Daftar Produk",
		expand: 'Lebih Banyak',
		classification: 'Belum ada klasifikasi produk',
		empty: "Tidak dapat menemukan produk yang Anda cari. Coba dengan kondisi yang berbeda",
		next_page: "Halaman Selanjutnya",
		prev_page: "Halaman Sebelumnya",
		footer_registration:'Nomor Registrasi'
	}
}