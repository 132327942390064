export const lang = {
	// Công cộng
	common: {
		// Tên gói ngôn ngữ
		name: 'Tiếng Trung',
		contact_us: 'Liên hệ chúng tôi',
		company_name: 'Công ty TNHH Bơm Van Nam Kinh Suo Ou',
		email: 'Email',
		tel: "Điện thoại",
		submit: 'Gửi',
		home: 'Trang chủ',
		header_searchInputPlaceholder: 'Vui lòng nhập từ khóa sản phẩm',
		header_pleaseLogin: 'Xin chào, vui lòng đăng nhập',
		header_freeRegistry: 'Đăng ký miễn phí',
		header_applyEnter: 'Đăng ký nhập hàng',
		header_myOrder: 'Đơn hàng của tôi',
		header_memberCenter: 'Trung tâm thành viên',
		header_helperCenter: 'Trung tâm trợ giúp',
		header_onlineService: 'Dịch vụ trực tuyến',
		header_phoneVersion: 'Phiên bản điện thoại',
		header_cart: 'Giỏ hàng của tôi',
		footer_customerService: 'Dịch vụ khách hàng',
		footer_onlineService: 'Dịch vụ trực tuyến',
		footer_feedback: 'Phản hồi vấn đề',
		footer_followUs: 'Theo dõi chúng tôi',
		footer_wechatPublic: 'Công khai WeChat',
		footer_webo: 'Weibo',
		footer_miniProgram: 'TikTok',
		footer_contract: 'Tạo hợp đồng trực tuyến',
		footer_logistics: 'Logistics đảm bảo',
		footer_quality: 'Đảm bảo chất lượng Suo Ou Mall',
		footer_aboutUs: 'Về chúng tôi',
		footer_helpCenter: 'Trung tâm trợ giúp',
		footer_afterSell: 'Dịch vụ sau bán hàng',
		footer_delivery: 'Giao hàng và kiểm tra',
		footer_cooperation: 'Hợp tác kinh doanh',
		footer_purchase: 'Mua sắm doanh nghiệp',
		footer_openPlatform: 'Nền tảng mở',
		footer_searchRecommend: 'Đề xuất tìm kiếm',
		footer_friendshipLink: 'Liên kết hữu nghị',
		footer_contactUs: 'Liên hệ chúng tôi 24 giờ',
		footer_address: 'Địa chỉ: Phòng 1603-1605, Tòa G1, Greenland Plaza, Số 8 Đường Tĩnh Viễn, Nam Kinh, Trung Quốc',
		hotSales: 'Bảng xếp hạng bán chạy 24 giờ',
		productsList: "Danh sách sản phẩm",
		expand: 'Mở rộng',
		classification: 'Chưa phân loại sản phẩm',
		empty: "Không tìm thấy sản phẩm bạn đang tìm kiếm. Thử với các điều kiện khác nhau",
		next_page: "Trang tiếp theo",
		prev_page: "Trang trước",
		footer_registration:'Số đăng ký'
	}
}