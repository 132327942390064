<template>
    <div class="mb-header">
        <el-menu :default-active="activeIndex" router class="el-menu-mb" mode="horizontal" @select="handleSelect">
            <el-menu-item :index="item.url" v-for="(item, index) in navList">{{ item.nav_title }}</el-menu-item>
            <el-menu-item style="width: 100px;"> <span style="color: #fff;">123456</span> </el-menu-item>
            <el-submenu style="position: fixed;top: 0;right: 0;background-color: #fff;" index="">
                <template slot="title"><i class="el-icon-search"></i></template>
                <div style="margin: 10px">
                    <el-input size="small" :placeholder="$t('header-pc.header_searchInputPlaceholder')" v-model="keyword" style="width: 100%" @keyup.enter="search">
                        <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
                    </el-input>
                </div>
            </el-submenu>
        </el-menu>
        <div class="company-info">
            <h2>{{ $lang("common.company_name") }}</h2>
            <div style="display: flex; align-items: center">
                <div class="img-container">
                    <img src="@/assets/logo.jpg" />
                </div>
                <div style="margin-left: 10px">{{ $t("header-pc.localion") }}</div>
                <div style="margin-left: 26px">
                    <el-select v-model="value" style="width: 100px" placeholder="" size="mini" @change="langSelect">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BScroll from "better-scroll"
    import { apiDefaultSearchWords, apiHotSearchWords } from "@/api/pc"
    import { cartList as apiCartList } from "@/api/goods/cart"
    import { navList } from "@/api/website"

    export default {
        data() {
            return {
                navItems: [
                    "首页",
                    "产品",
                    "公司档案",
                    "动态"
                    // 可以继续添加更多项以测试滑动效果
                ],
                scroll: null,
                activeIndex: "/index",
                isSearchBoxVisible: false,
                searchQuery: "",
                searchType: "goods",
                searchTypeText: "商品",
                keyword: "",
                hotSearchWords: [],
                defaultSearchWords: "",
                cartList: [],
                navList: [],
                cartTotalPrice: 0,
                navSelect: "",
                options: [
                    {
                        value: "en",
                        label: "English"
                    },
                    {
                        value: "ru",
                        label: "Русский язык"
                    },
                    {
                        value: "ar",
                        label: "اللغة العربية"
                    },
                    {
                        value: "vi",
                        label: "Tiếng Việt"
                    },
                    // {
                    //     value: "id",
                    //     label: "Bahasa Indonesia"
                    // },
                    {
                        value:"de",
                        label: "Deutsch"
                    },
                    {
                        value:"fr",
                        label:"Français"
                    }
                ],
                value: localStorage.getItem("langage") ? localStorage.getItem("langage") : "en"
            }
        },
        watch: {
            // 监听路由变化
            /*            '$route'(to, from) {
                this.activeIndex = to.path;  // 更新 activeIndex 为当前路由路径
                console.log('activeIndex: ',this.activeIndex)
            }*/
            $route: function (curr) {},
            searchType() {
                this.searchTypeText = this.searchType == "goods" ? "商品" : "店铺"
            },
            $route(curr) {
                if (this.keyword !== curr.query.keyword) {
                    this.keyword = curr.query.keyword
                }
                if (this.$route.name == "street") this.searchType = "shop"
                else this.searchType = "goods"
                this.initNav(curr.path)
            }
        },
        created() {
            this.activeIndex = this.$route.path

            // this.keyword = this.$route.query.keyword || '';
            if (this.$route.name == "street") this.searchType = "shop"
            else this.searchType = "goods"
            this.$store.dispatch("site/siteInfo")
            this.getHotSearchWords()
            this.getDefaultSearchWords()
            this.getCartList()
            this.nav()
        },
        methods: {
            nav() {
                navList({})
                    .then((res) => {
                        if (res.code == 0 && res.data) {
                            this.navList = res.data.list
                            for (let i in this.navList) {
                                this.navList[i]["url"] = JSON.parse(this.navList[i]["nav_url"]).url
                            }
                            this.initNav(this.$route.path)
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err.message)
                    })
            },
            initNav(path) {
                let queryParams = new URLSearchParams(this.$route.query).toString()
                if (queryParams) {
                    path += `?${queryParams}`
                }
                const cleanPath = path.split("?")[0] // 只保留路径部分
                for (let i in this.navList) {
                    if (this.navList[i].url == cleanPath) {
                        this.navSelect = cleanPath
                        break
                    }
                }
                console.log("this.navSelect: ", this.navSelect)
            },
            navUrl(url, target) {
                if (!url) return
                let lang = this.$route.query.lang
                if (!lang) {
                    lang = "en"
                }
                if (url.indexOf("?") !== -1) {
                    url = url + "&lang=" + lang
                } else {
                    url = url + "?lang=" + lang
                }
                if (target) window.open(url)
                else window.location.href = url
            },
            search() {
                if (this.searchType == "goods") this.$router.push({ path: "/list", query: { keyword: this.keyword } })
                else this.$router.push({ path: "/street", query: { keyword: this.keyword } })
                this.keyword = ""
            },
            getHotSearchWords() {
                apiHotSearchWords({}).then((res) => {
                    if (res.code == 0 && res.data.words) {
                        this.hotSearchWords = res.data.words.split(",")
                    }
                })
            },
            getDefaultSearchWords() {
                apiDefaultSearchWords({}).then((res) => {
                    if (res.code == 0 && res.data.words) {
                        this.defaultSearchWords = res.data.words
                    }
                })
            },
            // 获取购物车数据
            getCartList() {
                apiCartList({})
                    .then((res) => {
                        if (res.code >= 0 && res.data.length) {
                            this.cartTotalPrice = 0
                            this.cartList = res.data
                            this.cartList.forEach((item) => {
                                this.cartTotalPrice += item.discount_price * item.num
                            })
                            this.cartTotalPrice = this.cartTotalPrice.toFixed(2)
                        }
                    })
                    .catch((res) => {})
            },
            handleSelect(key, keyPath) {
                console.log("e2fhsej")
                console.log(key, keyPath)
                // 这里可以处理额外的点击事件，如果需要的话
                // 但由于我们使用了 `to` 属性，点击后会自动跳转，因此这个方法可能不需要额外处理
            },
            handleSelect(index) {
                // 处理菜单项选择
                this.activeIndex = index
                this.isSearchBoxVisible = true
            },
            langSelect(value) {
                localStorage.setItem("langage", value)
                this.$i18n.locale = value
                // location.reload()
                const newUrl = this.updateLangInQuery(value)

                // 刷新页面
                window.location.href = newUrl
            },
            updateLangInQuery(value) {
                const url = new URL(window.location.href)
                const params = new URLSearchParams(url.search)
                params.set("lang", value)
                url.search = params.toString()
                return url.toString()
            }
        }
    }
</script>

<style scoped lang="scss">
    .el-menu-mb::-webkit-scrollbar {
        display: none;
    }
    
    .el-menu-mb {
        position: fixed;
        display: flex;
        align-items: center;
        z-index: 100;
        width: 375px;
        overflow-x: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
    }
    .mb-header {
        /*padding-bottom: 61px;*/
    }
    .company-info {
        background-color: #fff;
        padding: 5.333vw;
        padding-top: 80px;
        border-bottom: 0.267vw solid #ddd;
    }
    .img-container {
        width: 60px;
        height: 60px;
        display: flex; /* 设定为flex容器 */
        justify-content: center; /* 在主轴方向（默认为水平方向）上居中对齐 */
        align-items: center; /* 在交叉轴方向（默认为垂直方向）上居中对齐 */
    }
    .search-box-wrapper {
        position: absolute;
        top: 60px; /* 根据需要调整 */
        right: 0;
        width: 300px; /* 根据需要调整 */
        background-color: white;
        border: 1px solid #ccc;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
        padding: 10px;
        z-index: 1000; /* 确保搜索框在其他内容之上 */
    }

    .search-box {
        width: calc(100% - 60px); /* 留出按钮的空间 */
    }
</style>
