export const lang = {
	// Gemeinsam
	common: {
		// Name des Sprachpakets
		name: 'Chinesisch',
		contact_us: 'Kontaktieren Sie uns',
		company_name: 'Nanjing Suoou Pumpen und Ventile Co., Ltd',
		email: 'E-Mail',
		tel: "Telefon",
		submit: 'Einreichen',
		home: 'Startseite',
		header_searchInputPlaceholder: 'Bitte geben Sie Produkt-Schlüsselwörter ein',
		header_pleaseLogin: 'Hallo, bitte melden Sie sich an',
		header_freeRegistry: 'Kostenlose Registrierung',
		header_applyEnter: 'Antrag auf Mitgliedschaft',
		header_myOrder: 'Meine Bestellungen',
		header_memberCenter: 'Mitgliederzentrum',
		header_helperCenter: 'Hilfezentrum',
		header_onlineService: 'Online-Kundenservice',
		header_phoneVersion: 'Handy-Version',
		header_cart: 'Mein Warenkorb',
		footer_customerService: 'Kundenservice',
		footer_onlineService: 'Online-Kundenservice',
		footer_feedback: 'Feedback',
		footer_followUs: 'Folgen Sie uns',
		footer_wechatPublic: 'WeChat öffentlicher Account',
		footer_webo: 'Weibo',
		footer_miniProgram: 'TikTok',
		footer_contract: 'Online-Vertragserstellung',
		footer_logistics: 'Zuverlässige Logistik',
		footer_quality: 'Qualitätsgarantie von Suoou Mall',
		footer_aboutUs: 'Über uns',
		footer_helpCenter: 'Hilfezentrum',
		footer_afterSell: 'After-Sales-Service',
		footer_delivery: 'Lieferung und Annahme',
		footer_cooperation: 'Geschäftliche Zusammenarbeit',
		footer_purchase: 'Unternehmenseinkauf',
		footer_openPlatform: 'Offene Plattform',
		footer_searchRecommend: 'Such-Empfehlungen',
		footer_friendshipLink: 'Freundschaftslinks',
		footer_contactUs: '24 Stunden Kontakt',
		footer_address: 'Adresse: Zimmer 1603-1605, Gebäude G1, Greenland Plaza, Nr. 8 Jingyuan Road, Nanjing, China',
		hotSales: '24-Stunden-Bestseller',
		productsList: "Produktliste",
		expand: 'Erweitern',
		classification: 'Noch keine Produktklassifizierung durchgeführt',
		empty: "Das gesuchte Produkt konnte nicht gefunden werden. Versuchen Sie es unter verschiedenen Bedingungen",
		next_page: "Nächste Seite",
		prev_page: "Vorherige Seite",
		footer_registration: 'Registrierungsnummer'
	}
}